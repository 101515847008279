// import $ from 'jquery';

export default () => {

  // window.addEventListener('DOMContentLoaded', () => {
    
    // plane JavaScript Code Here

  // });

  /* $(() => {
    
    // jQuery Code Here

  }); */

}